@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";  */

.warning-button{
  background: red !important;
  color: white !important;
}
.swal-text {
	text-align: center;
}
.swal-footer {
	text-align: center;
}
.swal-button {
	/* background-color: #a5dd72; */
}
.MuiAlert-message{
	white-space: pre-line !important;
}